"use client"

import { type FC } from "react"

import ErrorBoundaryShell from "@/components/misc/ErrorBoundary/ErrorBoundaryShell"

import type { ErrorPageProps } from "@/types/globals"

const GlobalErrorPage: FC<ErrorPageProps> = ({ error }) => (
  <div className="flex h-full">
    <ErrorBoundaryShell error={error} />
  </div>
)

export default GlobalErrorPage
